.circle-1 {
  max-width: 100%;
  overflow: hidden;
  position: relative;
  background-image: url("../images/bg-pattern-light.svg");
  background-size: cover;
  background-position: center;
}

.account-pages {
  align-items: center;
  display: flex;
  min-height: 100vh;
  justify-content: center;
}

.text-decoration {
  text-decoration: inherit;
}

.line-graph,
.bar-graph {
  height: 350px;
  max-width: 700px;
}

.chart-content-bg {
  background-color: #f9f9fd;
  margin: 10px 0;
}

.vdots-filter .nav-link {
  color: inherit;
  width: 25px;
  height: 40px;
}
.vdots-filter .dropdown-toggle::after {
  display: none;
}

.vdots-filter .dropdown-menu {
  min-width: 5rem !important;
}

.previous-value {
  float: right;
  font-size: 13px;
}

.admin-dashboard .crm-card {
  padding: 15px;
  margin-bottom: 15px;
}

.count-box .crm-card {
  min-height: 120px;
}

.chart-content-bg {
  min-height: 102px;
}

.status-type {
  display: flex;
  align-items: center;
  padding: 0 10px;
  text-transform: capitalize;
}

@media (min-width: 1400px) {
  .vdots-filter .dropdown-menu.show {
    transform: translate3d(-105px, 30px, 0px) !important;
  }
}

@media (min-width: 1050px) {
  .count-box .col {
    flex: 1 0;
    width: auto !important;
  }
}

.account-pages .ac-page {
  width: 40%;
}

@media (max-width: 767px) {
  .account-pages .ac-page {
    width: 50%;
  }
}

@media (max-width: 667px) {
  .account-pages .ac-page {
    width: 60%;
  }
}

@media (max-width: 567px) {
  .account-pages .ac-page {
    width: 75%;
  }
}

@media (max-width: 420px) {
  .account-pages .ac-page {
    width: 90%;
  }
}
